import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  logins: [],
  registrations: [],
  verifications: [],
  loadingAuth: false,
  users: [],
  datafixstatus:false,
  searhes:[],
  search:"",
  dashboardControl:undefined,
  lsdashboardControl:undefined
});

const getters = {
  allLogin: (state) => state.logins,
  allRegister: (state) => state.registrations,
  allVerify: (state) => state.verifications,
  allLoadingAuth: (state) => state.loadingAuth,
  allUser: (state) => state.users,
  allDataFixStatus:(state)=>state.datafixstatus,
  allSearches:(state)=>state.searhes,
  allSearch:(state)=>state.search,
  allDashboardControl:(state)=>state.lsdashboardControl
};

const actions = {
  async dashboardControl({commit},data){
    try{
      const response = await Axios.post(baseUrl + "dashboard/createControl",data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status  == 200 || response.status == 201){
        commit("setCreateControl",response.data);
      }
    
    }catch(error){
      console.log(error);
    }
  },
  async getDashboradControl({commit},data){
    try{
      const response = await Axios.post(baseUrl + "dashboard/dashboardControl",data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status  == 200){
        commit("getControl",response.data);
      }
    
    }catch(error){
      console.log(error);
    }
  },
  async setSearch({ commit },data) {

    try {
      const response = await Axios.post(baseUrl + "dashboard/searchCreate",data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (response.status === 201) {
        commit("setSearch", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setSearch", error.response);
    }
  },
  async getSearch({ commit },data) {

    try {
      const response = await Axios.get(baseUrl + "dashboard/searchSingle/"+data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (response.status === 200) {
        commit("setSearch", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setSearch", error.response);
    }
  },
  async getSearches({ commit },data) {
    try {
      const response = await Axios.get(baseUrl + "dashboard/searches/"+data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      if (response.status === 200) {
        commit("setSearches", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setSearches", error.response);
    }
  },
  async setDashStatus({ commit },data) {

    try {
      const response = await Axios.get(baseUrl + "dashboard/dashboardStatus/"+data);

      if (response.status === 200) {
        commit("setDashboard", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setDashboard", error.response);
    }
  },
  async getDashStatus({ commit },data) {

    try {
      const response = await Axios.get(baseUrl + "dashboard/statusDash/"+data);

      if (response.status === 200) {
        commit("setDashboard", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setDashboard", error.response);
    }
  },
  async commentCreate({ commit }, data) {

    try {
      const response = await Axios.post(baseUrl + "dashboard/addComment", data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
        },
      });

      if (response.status === 200) {
        commit("setComments", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setComments", error.response);
    }
  },
  async getComments({ commit }, data) {

    try {
      const response = await Axios.post(baseUrl + "dashboard/commentList", data,{
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).results.token,
        },
      });

      if (response.status === 200) {
        commit("setComments", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setComments", error.response);
    }
  },
  async login({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/login", data);
      if (response.status === 200) {
        router.push("/dashboard");
        localStorage.setItem("token", JSON.stringify(response.data));
        commit("setLoginData", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setLoginData", error.response);
      setTimeout(() => {
        commit("setLoginData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async verify({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.put(baseUrl + "user/verify", data);
      if (response.status === 200) {
        commit("setVerifyData", response.data);
        router.push("/");
        localStorage.removeItem("emailAuth");
      }
    } catch (error) {
      console.log(error);
      commit("setVerifyData", error.response);
      setTimeout(() => {
        commit("setVerifyData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  async register({ commit }, data) {
    this.dispatch("loadingAuth");
    try {
      const response = await Axios.post(baseUrl + "user/register", data);

      if (response.status === 201) {
        router.push("/verify");

        localStorage.setItem("emailAuth", data.email);
      }
      commit("setRegisterData", { data: response.data.results.message });
    } catch (error) {
      commit("setRegisterData", error.response);
      setTimeout(() => {
        commit("setRegisterData", []);
      }, 3000);
    }
    this.dispatch("loadingNotAuth");
  },
  
  async logout({ commit }) {
    try {
      if (process.browser) {
        if (localStorage.getItem("token")) {
          router.push("/");
          localStorage.clear();
        }
      }
    } catch (error) {
      console.log(error);
    }

    commit("setLoginData", []);
  },
  async getUsers({ commit }) {
    try {
      if (process.browser) {
        const response = await Axios.get(baseUrl + "user/users", {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
        if (response.status === 200) {
          commit("setUserData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async putUsers({ commit },data) {
    try {
      if (process.browser) {
        const response = await Axios.get(baseUrl + "user/user/"+data, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        });
        if (response.status === 200) {
          commit("setUserData", response.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  },
  async loadingAuth({ commit }) {
    commit("loadingAuthSet", true);
  },
  async loadingNotAuth({ commit }) {
    commit("loadingNotAuthSet", false);
  },
};

const mutations = {
  setLoginData: (state, user) => (state.logins = user),
  setUserData: (state, user) => (state.users = user),
  setRegisterData: (state, user) => (state.registrations = user),
  setVerifyData: (state, user) => (state.verifications = user),
  loadingAuthSet: (state, loading) => (state.loadingAuth = loading),
  loadingNotAuthSet: (state, loading) => (state.loadingAuth = loading),
  setComments:(state,data)=>(state.comments = data),
  setDashboard:(state,data)=>(state.datafixstatus = data),
  setSearches:(state,data)=>(state.searhes = data),
  setSearch:(state,data)=>(state.search = data),
  setCreateControl:(state,data)=>(state.dashboardControl = data),
  getControl:(state,data)=>(state.lsdashboardControl = data)
};

export default { state, getters, actions, mutations };
