import Vue from 'vue'
import VueRouter from 'vue-router'
import backendmenu from './backend';
import authorization from './authorization';

Vue.use(VueRouter)

const routes = [
  ...backendmenu,
  ...authorization
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
