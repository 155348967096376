import Axios from "axios";
import store from "..";
import { baseUrl } from "../../main";

const state = () => ({
  searchdata: [],
  propertypagition: [],
  propertydetail: [],
  singleProperty: [],
  paginationProperty: [],
  searchCode: [],
  categorytosearch: [],
  dashboardCount: [],
  mldata: [],
  fasttabledata: [],
  propertyTypeData: [],
  newBuildProperty: [],
  retirementProperty: [],
  ownershipProperty: [],
  distinctType: [],
  rightmovesSales: [],
  rightmovesRent: [],
  zooplaSales: [],
  zooplaRents: [],
});

const getters = {
  allSearchData: (state) => state.searchdata,
  allPropertyDetailData: (state) => state.propertydetail,
  allSingleProperty: (state) => state.singleProperty,
  allPaginationProperty: (state) => state.paginationProperty,
  allSearchCode: (state) => state.searchCode,
  allCategoryToSearch: (state) => state.categorytosearch,
  allDashboardCount: (state) => state.dashboardCount,
  allMLdata: (state) => state.mldata,
  allFastTableData: (state) => state.fasttabledata,
  allPropertyTypedata: (state) => state.propertyTypeData,
  allNewBuilddata: (state) => state.newBuildProperty,
  allRetirementdata: (state) => state.retirementProperty,
  allOwnershipdata: (state) => state.ownershipProperty,
  allDistinctType: (state) => state.distinctType,
  allRightMoves: (state) => state.rightmovesSales,
  allRightMovesRents: (state) => state.rightmovesRent,
  allZooplaSales: (state) => state.zooplaSales,
  allZooplaRents: (state) => state.zooplaRents,
};

const actions = {
  async fetchSearchData({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "searchdata", data);
      commit("setSearchData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPropertyDetails({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "propertydetail", data);
      console.log(searchdata.data);
      commit("setPropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchPaginationPropertyDetails({ commit }, data) {
    try {
      const searchdata = await Axios.post(baseUrl + "propertypagination", data);
      console.log(searchdata.data);
      commit("setPropertyPagination", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchSearchCodeData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "searchdata/searchcode",
        data
      );
      console.log(searchdata.data);
      commit("setSearchCode", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSinglePropertyDetails({ commit }, id) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "properties/singleProperty/" + id,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSinglePropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getSingleRentPropertyDetails({ commit }, id) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "properties/singleRentProperty/" + id,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setSinglePropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async updateProperty({ commit }, data) {
    try {
      const response = await Axios.put(baseUrl + `properties/singleProperty`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setSinglePropertyDetail", response.data);
    } catch (error) {
      console.log(error);
    };
  },
  async getSinglePropertyDetailsZoopla({ commit }, id) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/singledatazoopla/" + id
      );
      console.log(searchdata.data);
      commit("setSinglePropertyDetail", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchcategorytosearch({ commit }) {
    try {
      const paginatedproperty = await Axios.post(baseUrl + "pageurl");
      commit("setCategoryToSearch", paginatedproperty.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dashboardCount({ commit }) {
    try {
      const paginatedproperty = await Axios.get(baseUrl + "dashboard");
      commit("setDashboardCount", paginatedproperty.data);
    } catch (error) {
      if (error.response.status == 403) {
        store.dispatch("logout");
      }
      console.log(error);
    }
  },
  async fetchMlData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertydetail/mldata",
        data
      );
      commit("setMlData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchNewScraped({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertypagination/rightmoves",
        data
      );
      console.log(searchdata.data)
      commit("setRightMoves", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchZooplaScrape({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertypagination/zooplasales",
        data
      );
      commit("setZooplaSales", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchRightRent({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertypagination/rightmoves",
        data
      );
      commit("setRightRent", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchZooplaRent({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertypagination/rightmoves",
        data
      );
      commit("setZooplaRent", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },


  async fetchFasttableData({ commit }, data) {
    try {
      const searchdata = await Axios.post(
        baseUrl + "propertydetail/rightmoves",
        data
      );
      commit("setFasttableData", searchdata.data);
    } catch (error) {
      console.log(error);
    }
  },
  async dataAnalysisForPropertyType({ commit }, data) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/distinctpropertytype",
        data
      );
      commit("setDistintProperty", searchdata.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async dataAnalysisForNewBuild({ commit }, data) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/newbuild",
        data
      );
      commit("setNewBuildProperty", searchdata.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async dataAnalysisForRetirement({ commit }, data) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/retirementproperty",
        data
      );
      commit("setRetirementProperty", searchdata.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async dataAnalysisForOwnership({ commit }, data) {
    try {
      const searchdata = await Axios.get(
        baseUrl + "propertydetail/ownership",
        data
      );
      commit("setOwnership", searchdata.data.results);
    } catch (error) {
      console.log(error);
    }
  },

  async filterProperty({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + 'propertydetail/filterproperty', data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      })
      commit("setPropertyDetail", response.data)
    } catch (error) {
      console.log(error);
    }
  },

  async getFloodrisk({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + 'propertydetail/floodrisk/' + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status == 200){
        commit("setSinglePropertyDetail", response.data);
      }
    } catch (error) {
      if (error.response.status == 403) {
        store.dispatch("logout");
      }
      console.log(error);
    }
  },

  async getRentAssign({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + 'propertydetail/rentAssgin/' + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status == 200){
        commit("setSinglePropertyDetail", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },

  async getEpcAssign({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + 'propertydetail/epcFinder/' + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status == 200){
        commit("setSinglePropertyDetail", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getPropertyUpdate({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + 'propertydetail/propertyUpdate/' + data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if(response.status == 200){
        commit("setSinglePropertyDetail", response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
};

const mutations = {
  setSearchData: (state, search) => (state.searchdata = search),
  setPropertyDetail: (state, search) => (state.propertydetail = search),
  setSinglePropertyDetail: (state, search) => (state.singleProperty = search),
  setPropertyPagination: (state, search) => (state.paginationProperty = search),
  setSearchCode: (state, search) => (state.searchCode = search),
  setCategoryToSearch: (state, search) => (state.categorytosearch = search),
  setDashboardCount: (state, countdata) => (state.dashboardCount = countdata),
  setMlData: (state, countdata) => (state.mldata = countdata),
  setDistintProperty: (state, countdata) =>
    (state.propertyTypeData = countdata),
  setNewBuildProperty: (state, countdata) =>
    (state.newBuildProperty = countdata),
  setRetirementProperty: (state, countdata) =>
    (state.retirementProperty = countdata),
  setOwnership: (state, countdata) => (state.ownershipProperty = countdata),
  setFasttableData: (state, countdata) => (state.fasttabledata = countdata),
  setDistinctType: (state, countdata) => (state.distinctType = countdata),
  setRightMoves: (state, countdata) => (state.rightmovesSales = countdata),
  setZooplaSales: (state, countdata) => (state.zooplaSales = countdata),
  setRightRent: (state, countdata) => (state.rightmovesRent = countdata),
  setZooplaRent: (state, countdata) => (state.zooplaRents = countdata),
};

export default { state, getters, actions, mutations };
