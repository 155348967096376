import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  refurbishment: [],
  sharedOwnership: [],
  retirement: [],
  soldStc: [],
  auction: [],
  parkHome: [],
  applyCategory: [],
  applyLHARentOneBedroom: [],
  applyLHARentTwoBedroom: [],
  applyLHARentThreeBedroom: [],
  applyLHARentFourBedroom: [],
  applyLHAGrossNet: [],
  applyRentGrossNet: [],
  applyRentValution: [],
  loadingML:false
});

const getters = {
  allrefurbishment: (state) => state.refurbishment,
  allsharedOwnership: (state) => state.sharedOwnership,
  allretirement: (state) => state.retirement,
  allsoldStc: (state) => state.soldStc,
  allauction: (state) => state.auction,
  allparkHome: (state) => state.parkHome,
  allapplyCategory: (state) => state.applyCategory,
  allapplyLHARentOneBedroom: (state) => state.applyLHARentOneBedroom,
  allapplyLHARentTwoBedroom: (state) => state.applyLHARentTwoBedroom,
  allapplyLHARentThreeBedroom: (state) => state.applyLHARentThreeBedroom,
  allapplyLHARentFourBedroom: (state) => state.applyLHARentFourBedroom,
  allapplyLHAGrossNet: (state) => state.applyLHAGrossNet,
  allapplyRentGrossNet: (state) => state.applyRentGrossNet,
  allapplyRentValution: (state) => state.applyRentValution,
  allLoadingML: (state) => state.loadingML,
};

const actions = {
  async assignRefurbishment({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/refurishstatus");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignSharedOwnership({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/sharedownership");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignRetirement({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/retirement");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignSoldStc({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/soldstc");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignAuction({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/auction");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignParkhome({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/parkhome");
      commit("setRefurishment", res.data);
    } catch (error) {
      console.log(error);
      commit("setRefurishment", []);
    }
    this.dispatch('notloading');
  },
  async assignCategory({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/assingCategory");
      commit("setapplyCategory", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyCategory", []);
    }
    this.dispatch('notloading');
  },
  async assignLHARentOneBedroom({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "propertydetail/applyLHARentOneBedRoom");
      commit("setapplyLHARentOneBedroom", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyLHARentOneBedroom", []);
    }
    this.dispatch('notloading');
  },
  async assignLHARentTwoBedroom({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "propertydetail/applyLHARentTwoBedRoom");
      commit("setapplyLHARentTwoBedroom", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyLHARentTwoBedroom", []);
    }
    this.dispatch('notloading');
  },
  async assignLHARentThreeBedroom({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "propertydetail/applyLHARentThreeBedRoom");
      commit("setapplyLHARentThreeBedroom", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyLHARentThreeBedroom", []);
    }
    this.dispatch('notloading');
  },
  async assignLHARentFourBedroom({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "propertydetail/applyLHARentFourBedRoom");
      commit("setapplyLHARentFourBedroom", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyLHARentFourBedroom", []);
    }
    this.dispatch('notloading');
  },
  async assignLHAGrossAndNet({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "refurishstatus");
      commit("setapplyapplyLHAGrossNet", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyapplyLHAGrossNet", []);
    }
    this.dispatch('notloading');
  },
  async assignRentGrossNet({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "refurishstatus");
      commit("setapplyRentGrossNet", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyRentGrossNet", []);
    }
    this.dispatch('notloading');
  },
  async assignRentValution({ commit }) {
    this.dispatch('loading');
    try {
      const res = await Axios.get(baseUrl + "processml/valuateRents");
      commit("setapplyRentValution", res.data);
    } catch (error) {
      console.log(error);
      commit("setapplyRentValution", []);
    }
    this.dispatch('notloading');
  },
  async loading({commit}){
    try{
      commit("setLoadingML",true);
    }catch(error){
      console.log(error);
    }
  },
  async notloading({commit}){
    try{
      commit("setLoadingML",false);
    }catch(error){
      console.log(error);
    }
  },
};

const mutations = {
  setRefurishment: (state, data) => (state.refurbishment = data),
  setapplyCategory: (state, data) => (state.applyCategory = data),
  setapplyLHARentOneBedroom: (state, data) => (state.applyLHARentOneBedroom = data),
  setapplyLHARentTwoBedroom: (state, data) => (state.applyLHARentTwoBedroom = data),
  setapplyLHARentThreeBedroom: (state, data) => (state.applyLHARentThreeBedroom = data),
  setapplyLHARentFourBedroom: (state, data) => (state.applyLHARentFourBedroom = data),
  setapplyapplyLHAGrossNet: (state, data) => (state.applyLHAGrossNet = data),
  setapplyRentGrossNet: (state, data) => (state.applyRentGrossNet = data),
  setapplyRentValution: (state, data) => (state.applyRentValution = data),
  setLoadingML: (state, data) => (state.loadingML = data),
};

export default { state, getters, actions, mutations };
