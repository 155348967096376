<template>
  <section>
    <div class="redFlags" v-if="allDataFixStatus.results">
      <marquee
        >PRESENTLY EXPERIENCING DATA ISSUES ON SOME AREA AND WORKING TO FIX THIS
        ASAP</marquee
      >
    </div>
    <v-app>
      <v-navigation-drawer
        app
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        class="backmenu"
      >
        <v-list-item class="px-2 logoBox mb-4">
          <img src="@/assets/logo.svg" class="logo" />
        </v-list-item>

        <v-list dense>
          <Header />

        </v-list>
      </v-navigation-drawer>
      <v-app-bar app>
        <ul class="right-actions">
          <li><v-icon>mdi-account</v-icon> {{userData.fullname}}</li>
        </ul>
      </v-app-bar>
      <v-main>
        <v-container fluid>
          <router-view :key="$route.fullPath"></router-view>
         
        </v-container>
      </v-main>

      <v-footer app>
        <!-- -->
        {{ new Date().getFullYear() }} - TVA
      </v-footer>
    </v-app>
  </section>
</template>

<script>
import Header from "@/components/dashboard/Header";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Dashboard",
  components: {
    Header,
  },
  data: () => ({
    clipped: false,
    fixed: false,
    drawer: true,
    mini: false,
    userData:undefined
  }),
  methods: {
    ...mapActions([
      "getDashStatus"
    ]),
 
  },
  computed: {
    ...mapGetters(["allDataFixStatus","allLogin"]),
  },
  async created() {
    if (localStorage.getItem("token") == undefined) {
      this.$router.push("/");

    }else{
      this.userData = JSON.parse(localStorage.getItem("token")).user;
    }

    await this.getDashStatus("DataInfo");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.backmenu {
  background: $menucolor !important;
  .v-list-item__title {
    color: $white;
  }
  .v-icon {
    color: $white;
  }
}
.logoBox {
  width: 150px;
  margin: 10px auto;
  .logo{
    width: 150px;
  }
}
.logoutBtn{
  color:#fff;
  padding: 0 25px;
  font-size: 15px;
  cursor: pointer;
  .v-icon{
    margin-right: 5px;
  }
}
img {
  filter: brightness(1000);
}
.right-actions {
  display: flex;
  width: 100%;
  list-style: none;
  padding-left: 0;
  justify-content: flex-end;
}
.v-icon {
  color: $white !important;
}
.right-actions {
  li{
    display: flex;
    align-items: center;
  
  }
  
  .v-icon {
    color: #000 !important;
    height: 35px;
    width: 35px;
    border:1px solid rgb(2, 2, 2);
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
  }
}
.redFlags {
  background: red;
  position: fixed;
  left: 255px;
  top: 0;
  right: 0;
  z-index: 999;
  color: #fff;
  font-weight: 600;
  padding-top: 5px;
  letter-spacing: 2px;
}
.v-sheet{
  z-index: 999;
}
</style>
