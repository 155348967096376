<template>
  <v-app id="app">
    <router-view :key="$route.fullPath"></router-view>
  </v-app>
</template>
<script>
export default {
  name: "App",
  data: () => ({}),
};
</script>

<style lang="scss">
@import "@/assets/scss/variables.scss";
html,
body {
  padding: 0;
  margin: 0;
}

</style>
