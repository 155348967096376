import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  calculator: undefined,
  whitelabels: [],
  whitelabel: undefined,
  offers: [],
});

const getters = {
  allCalculator: (state) => state.calculator,
  allWhiteLabels: (state) => state.whitelabels,
  allWhiteLabel: (state) => state.whitelabel,
  allOffers: (state) => state.offers,
};

const actions = {
  async postCalculator({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "whitelabel/calculatorValue",
        data
      );
      commit("setCaculator", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getWhiteLabels({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "whitelabel/whiteLabels",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setWhiteLabels", response.data.results);
    } catch (error) {
      console.log(error);
    }
  },
  async getWhiteLabel({ commit }, data) {
    try {
      const response = await Axios.get(
        baseUrl + "whitelabel/whiteLabel/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setWhiteLabel", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async postWhiteLabel({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "whitelabel/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setWhiteLabel", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteSingleProperty({ commit }, data) {
    try {
      const response = await Axios.delete(
        baseUrl + "whitelabel/deleteOffmarketProperties/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setWhiteLabels", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getOffers({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "whitelabel/allOffers",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setOffers", response.data);
    } catch (error) {
      console.log(error);
    }
  },
};

const mutations = {
  setCaculator: (state, data) => (state.calculator = data),
  setWhiteLabels: (state, data) => (state.whitelabels = data),
  setWhiteLabel: (state, data) => (state.whitelabel = data),
  setOffers: (state, data) => (state.offers = data),
};

export default { state, getters, actions, mutations };
