import Authorization from '../views/authorization/Authorization'
const backendmenu = [
    {
        path: '/',  component: Authorization,
        children: [
          {
            path: '/',
            name: 'Login',
            component: () => import(/* webpackChunkName: "Login" */ '../views/authorization/views/Login')
          },
          {
            path: '/verify',
            name: 'Verify',
            component: () => import(/* webpackChunkName: "Verify" */ '../views/authorization/views/Verify')
          },
          {
            path: '/registration',
            name: 'Register',
            component: () => import(/* webpackChunkName: "Register" */ '../views/authorization/views/Register')
          },
        ]
    }
]

export default backendmenu;