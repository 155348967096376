const state = () => ({
  adminMenus: [
    {
      title: "On Market Properties",
      open: false,
      path: "/dashboard",
      icon: "mdi-view-list",
      submenu: [],
    },
  ],
  specificPage: [
    {
      name: "rigthmoves",
      menus: [
        {
          title: "Detail Properties",
          path: "/dashboard/propertydetail",
          count: 10,
        },
        {
          title: "Paginated Properties",
          path: "/dashboard/paginatedproperty",
          count: 10,
        },
        {
          title: "Searching Code",
          path: "/dashboard/searchcode",
          count: 10,
        },
      ],
    },
  ],
});

const getters = {
  allAdminMenus: (state) => Object.assign(state.adminMenus),
  allSpecificMenus: (state) => Object.assign(state.specificPage),
};

const actions = {};

const mutations = {
  menuMutation: (state, menu) => (state.menus = menu),
};

export default { state, getters, actions, mutations };
