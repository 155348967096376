<template>
  <section class="authbox">
    <router-view :key="$route.fullPath"></router-view>
  </section>
</template>

<script>
export default {
  name: "Authorization",
};
</script>

<style lang="scss" scoped>
.authbox {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
