import Axios from "axios";
import { baseUrl } from "../../main";

const state = () => ({
  scraperList: [],
  flagSettersList: [],
  propertyDetails: [],
  emailTemplates: [],
  emailTemplate: undefined,
  emailTest:undefined
});

const getters = {
  allScraperList: (state) => state.scraperList,
  allFlagSettersList: (state) => state.flagSettersList,
  allPropertyDetails: (state) => state.propertyDetails,
  allEmailTemplates: (state) => state.emailTemplates,
  allEmailTemplate: (state) => state.emailTemplate,
};

const actions = {

  getAllScraperList: async ({ commit }) => {
    try {
      const response = await Axios.get(baseUrl + "dashboard/listOfScraper");
      if (response.status == 200) {
        commit("setScraperMut", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setScraperMut", undefined);
    }
  },
  getAllFlagSettersList: async ({ commit }) => {
    try {
      const response = await Axios.get(baseUrl + "dashboard/listOfFlagSetters");
      if (response.status == 200) {
        commit("setFlagSettersMut", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setFlagSettersMut", undefined);
    }
  },
  getAllPropertyDetails: async ({ commit }) => {
    try {
      const response = await Axios.get(baseUrl + "dashboard/overviewproperty");
      if (response.status == 200) {
        commit("setAllPropertyDetailsMut", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setAllPropertyDetailsMut", undefined);
    }
  },
  async listEmailTempaltes({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "emailtemplate/listTemplate", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAllEmailTemplatesMut", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async singleEmailTemplate({ commit }, data) {
    try {
      const response = await Axios.get(baseUrl + "emailtemplate/singleTemplate/"+ data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAllEmailTemplateMut", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async createEmailTemplate({ commit }, data) {
    try {
      const response = await Axios.post(baseUrl + "emailtemplate/create", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setAllEmailTemplateMut", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  testEmail: async ({ commit },data) => {
    try {
      const response = await Axios.post(baseUrl + "emailtemplate/emailTempalteTest",data);
      if (response.status == 200) {
        commit("setScraperMut", response.data);
      }
    } catch (error) {
      console.log(error);
      commit("setScraperMut", undefined);
    }
  },
};

const mutations = {
  menuMutation: (state, menu) => (state.menus = menu),
  setScraperMut: (state, data) => (state.scraperList = data),
  setFlagSettersMut: (state, data) => (state.flagSettersList = data),
  setAllPropertyDetailsMut: (state, data) => (state.propertyDetails = data),
  setAllEmailTemplatesMut: (state, data) => (state.emailTemplates = data),
  setAllEmailTemplateMut: (state, data) => (state.emailTemplate = data),
};

export default { state, getters, actions, mutations };
