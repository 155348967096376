import axios from "axios";
import Axios from "axios";
import { baseUrl } from "../../main";
import router from "../../router";

const state = () => ({
  rightmovessales: [],
  postcodes: [],
  postcodeprefix: [],
  loadingProperty: true,
  prefixpostcode: [],
  rentvalutions: [],
  rightmovesdownload: [],
  loadingDownload: false,
  northSideDetails: [],
  rentAndPrice: [],
  flagsCount: [],
  propertyTypeCount: [],
  regions: [],
  propertyinquiry: [],
  comment: undefined,
  comments: [],
  propertiesCount: 0,
  propertiesReport: undefined,
  postcodCountReport: undefined,
  wishlists: [],
  rentProperties: [],
  offMarketProperties: [],
  importCSV: undefined,
  offMarketProperty: undefined,
  fixImage: undefined,
  publishedProperty: undefined,
  images: [], // Paul added
});

const getters = {
  allRightMovesSale: (state) => state.rightmovessales,
  allRightMovesSaleDetail: (state) => state.rightmovessalesdetails,
  allZooplaSaleDetail: (state) => state.zooplasalesdetails,
  allRightMovesRent: (state) => state.rightmovesrents,
  allPostCodes: (state) => state.postcodes,
  allLoadingProperty: (state) => state.loadingProperty,
  allPostCodePrefix: (state) => state.postcodeprefix,
  allPrefixPostCode: (state) => state.prefixpostcode,
  allRightmoveDownload: (state) => state.rightmovesdownload,
  allLoadingDownload: (state) => state.loadingDownload,
  allNorthSideDetails: (state) => state.northSideDetails,
  allRentAndPrice: (state) => state.rentAndPrice,
  allFlagsCount: (state) => state.flagsCount,
  allPropertyTypeCount: (state) => state.propertyTypeCount,
  allRegion: (state) => state.regions,
  allPropertyInquiry: (state) => state.propertyinquiry,
  allComments: (state) => state.comments,
  allComment: (state) => state.comment,
  allPropertiesCount: (state) => state.propertiesCount,
  allPropertyReport: (state) => state.propertiesReport,
  allpostcodCountReport: (state) => state.postcodCountReport,
  allWishlist: (state) => state.wishlists,
  allRentProperties: (state) => state.rentProperties,
  allOffMarketProperties: (state) => state.offMarketProperties,
  allOffMarketProperty: (state) => state.offMarketProperty,
  allImportCSV: (state) => state.importCSV,
  allPublishedProperty: (state) => state.publishedProperty,
  allImages: (state) => state.images, // Paul added
};

const actions = {
  async postWishlist({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}propertydetail/createWishlist`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setWishlistProperty", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async propertiesCount({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/northpropertyCount`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setPropertiesCount", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async propertyVerfication({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "propertydetail/propertyValidate",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setPropertyVerification", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getPropertyInquiry({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/sendInquiry`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setPropertyInquiry", response.data);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        localStorage.clear();
        router.push("/");
      }
    }
  },
  async resgetPropertyInquiry({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/resendInquiry`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPropertyInquiry", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async reogetPropertyInquiry({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/reofferInquiry`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPropertyInquiry", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async issgetPropertyInquiry({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/isStillEmailInquiry`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPropertyInquiry", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getRegion({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}processml/region`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setRegionSelect", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getRegionZoopla({ commit }, data) {
    try {
      const response = await Axios.get(`${baseUrl}properties/region`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setRegionZooplaSelect", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getPrefixPostCodes({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertydetail/prefixpostcode`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPrefixPostCode", response.data);
    } catch (error) {
      console.log(error);
      commit("setPrefixPostCode", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getPostCodes({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertydetail/postcodes`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPostCodes", response.data);
    } catch (error) {
      console.log(error);
      commit("setPostCodes", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getPrePostCodes({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.get(
        `${baseUrl}propertydetail/getAllPostcode`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPostCodePrefix", response.data);
    } catch (error) {
      console.log(error);
      commit("setPostCodePrefix", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getPropertyFilterRigthmoves({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertydetail/filterpropertyrightmoves`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setRightMovesSalesDetails", response.data);
    } catch (error) {
      console.log(error);
      commit("setRightMovesSalesDetails", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getPropertyFilterZoopla({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertydetail/filterpropertyzoopla`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setZooplaSaleDetails", response.data);
    } catch (error) {
      console.log(error);
      commit("setZooplaSaleDetails", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getRightMovesSalesDetail({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertypagination/rightmovesdetailsale`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setRightMovesSalesDetails", response.data);
    } catch (error) {
      console.log(error);
      commit("setRightMovesSalesDetails", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getRightMovesSalesDetailVal({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}propertydetail/rightmovesval`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setRightMovesSalesDetails", response.data);
    } catch (error) {
      console.log(error);
      commit("setRightMovesSalesDetails", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getFilterDownload({ commit }, data) {
    try {
      commit("setloadingDownload", true);
      const response = await Axios.post(
        `${baseUrl}propertydetail/getDownloadListEmailProperty`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setRightMovesSalesDownload", response.data);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        localStorage.clear();
        router.push("/");
      }
      commit("setRightMovesSalesDownload", error.response);
    }
    commit("setloadingDownload", false);
  },

  async getRentProperties({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}properties/rentproperties`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setRentProperties", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async loadingStart({ commit }) {
    commit("setloadingStart", false);
  },
  async loadingStop({ commit }) {
    commit("setloadingStop", true);
  },

  async getNorthSideDetail({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}properties/northproperty`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setNorthSideDetail", response.data);
    } catch (error) {
      console.log(error);
      commit("setNorthSideDetail", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getTestDetail({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}properties/testProperty`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setNorthSideDetail", response.data);
    } catch (error) {
      console.log(error);
      commit("setNorthSideDetail", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getNtestProperty({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}properties/testProperty`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setNorthSideDetail", response.data);
    } catch (error) {
      console.log(error);
      commit("setNorthSideDetail", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getZooplaDetail({ commit }, data) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.post(
        `${baseUrl}properties/zooplanorthproperty`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setNorthSideDetail", response.data);
    } catch (error) {
      console.log(error);
      commit("setNorthSideDetail", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getAllRentAndPrice({ commit }) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.get(
        `${baseUrl}dashboard/listofrentandprice`
      );
      commit("setAllRentAndPrice", response.data);
    } catch (error) {
      console.log(error);
      commit("setAllRentAndPrice", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getPropertyTypeCount({ commit }) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.get(`${baseUrl}dashboard/propertyTypeCount`);
      commit("setAllPropertyTypeCountMut", response.data);
    } catch (error) {
      console.log(error);
      commit("setAllPropertyTypeCountMut", error.response);
    }
    this.dispatch("loadingStop");
  },
  async getListOfFlagsCount({ commit }) {
    try {
      this.dispatch("loadingStart");
      const response = await Axios.get(`${baseUrl}dashboard/listoflagscount`);
      commit("setListOfFlagsCount", response.data);
    } catch (error) {
      console.log(error);
      commit("setListOfFlagsCount", error.response);
    }
    this.dispatch("loadingStop");
  },
  async addComment({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}dashboard/addComment`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setComments", response.data);
    } catch (error) {
      console.log(error);
      commit("setComments", response.data);
    }
  },
  async listOfComments({ commit }, data) {
    try {
      const response = await Axios.post(`${baseUrl}dashboard/comments`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });

      commit("setComments", response.data);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        localStorage.clear();
        router.push("/");
      }
      commit("setComments", error.response);
    }
  },
  async deleteComment({ commit }, data) {
    try {
      const response = await Axios.delete(
        baseUrl + `dashboard/comment/${data}`
      );
      commit("setComments", response.data);
    } catch (error) {
      console.log(error);
      commit("setComments", error.response);
    }
  },

  async propertyReport({ commit }, data) {
    try {
      const response = await Axios.post(
        `${baseUrl}dashboard/propertyreport`,
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );

      commit("setPropertyReport", response.data);
    } catch (error) {
      console.log(error);
      if (error.response.status == 403) {
        localStorage.clear();
        router.push("/");
      }
    }
  },
  async postcodeCountReport({ commit }) {
    try {
      const response = await Axios.get(`${baseUrl}dashboard/postcodeReport`);
      commit("setPostCountReport", response.data);
    } catch (error) {
      console.log(error);
    }
  },

  async getRentDataEmail({ commit }, data) {
    try {
      const response = await Axios.post(
        baseUrl + "propertydetail/getDownloadListEmailRentProperty",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setRightMovesSales", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async getOffMarketProperties({ commit }, data) {
    try {
      const repsonse = await axios.post(
        baseUrl + "whitelabel/offmarketproperties",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postImportCSV({ commit }, data) {
    try {
      const repsonse = await axios.post(
        baseUrl + "whitelabel/importcsv",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 201) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
      commit("setImportCSV", error.response.data);
    }
  },
  async postPropertyPost({ commit }, data) {
    try {
      const repsonse = await axios.post(baseUrl + "whitelabel/publish", data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (repsonse.status == 200) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
      commit("setImportCSV", error.response.data);
    }
  },
  async postPropertyReserve({ commit }, data) {
    try {
      const repsonse = await axios.post(
        baseUrl + "whitelabel/reserveByAdmin",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
      commit("setImportCSV", error.response.data);
    }
  },
  async postPropertyUnReserve({ commit }, data) {
    try {
      const repsonse = await axios.post(
        baseUrl + "whitelabel/unreserve",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
      commit("setImportCSV", error.response.data);
    }
  },
  async getProperty({ commit }, data) {
    try {
      const repsonse = await axios.get(
        baseUrl + "whitelabel/singleOffProperty/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setOffMarketProperty", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async putPropertyOffMarket({ commit }, data) {
    try {
      const repsonse = await axios.put(
        baseUrl + "whitelabel/updateOffMarketProperty/",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setOffMarketProperty", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async postPropertyOffMarket({ commit }, data) { // Paul added
    try {
      const response = await axios.post(`${baseUrl}whitelabel/insert-off-market-properties`, data, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      if (response.status === 201) {
        commit("addProperty", response.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error.response, null, 2));
      throw error;
    }
  },
  async getPublishedProperties({ commit }, data) {
    try {
      const repsonse = await axios.post(
        baseUrl + "whitelabel/publishedProperties",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      if (repsonse.status == 200) {
        commit("setImportCSV", repsonse.data);
      }
    } catch (error) {
      console.log(error);
    }
  },
  async getFixImage({ commit }, data) {
    try {
      const response = await Axios.get(
        `https://whitelabel.truevals.com/api/v1/whitelabel/imageRegenerate/${data}`,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setFixImage", response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async deleteCSV({ commit }) {
    try {
      const response = await Axios.delete(baseUrl + `whitelabel/deleteCSV`, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        },
      });
      commit("setComments", response.data);
    } catch (error) {
      console.log(error);
      commit("setComments", error.response);
    }
  },
  async getPublishedProperty({ commit }, data) {
    try {
      const repsonse = await axios.get(
        baseUrl + "whitelabel/singleProperty/" + data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPublishedProperty", repsonse.data);
    } catch (error) {
      console.log(error);
    }
  },
  async putPublishedProperty({ commit }, data) {
    try {
      const repsonse = await axios.put(
        baseUrl + "whitelabel/singleProperty",
        data,
        {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(localStorage.getItem("token")).token,
          },
        }
      );
      commit("setPublishedProperty", repsonse.data);
    } catch (error) {
      console.log(error);
    }
  },
  async uploadImages({ commit }, data) {
    try{
      const response = await Axios.post(baseUrl + `whitelabel/uploadImages`, data, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        }
      });
      commit("setUploadImages", response.data);
    }catch(error){
      console.log(error);
    }
  },
  async deleteImage({ commit }, data) {
    try{
      const response = await Axios.delete(baseUrl + `whitelabel/deleteImage/${data}`, {
        headers: {
          Authorization: "Bearer " + JSON.parse(localStorage.getItem("token")).token,
        }
      });
      commit("setUploadImages", response.data);
    }catch(error){
      console.log(error);
    }
  }
};

const mutations = {
  setRightMovesSales: (state, data) => (state.rightmovessales = data),
  setRightMovesSalesDownload: (state, data) =>
    (state.rightmovesdownload = data),
  setRightMovesUpdateRent: (state, data) => (state.rentvalutions = data),
  setRightMovesSalesDetails: (state, data) =>
    (state.rightmovessalesdetails = data),
  setRightMovesRents: (state, data) => (state.rightmovesrents = data),
  setloadingStart: (state, data) => (state.loadingProperty = data),
  setloadingStop: (state, data) => (state.loadingProperty = data),
  setPostCodes: (state, data) => (state.postcodes = data),
  setPostCodePrefix: (state, data) => (state.postcodeprefix = data),
  setPrefixPostCode: (state, data) => (state.prefixpostcode = data),
  setloadingDownload: (state, data) => (state.loadingDownload = data),
  setNorthSideDetail: (state, data) => (state.northSideDetails = data),
  setAllRentAndPrice: (state, data) => (state.rentAndPrice = data),
  setAllPropertyTypeCountMut: (state, data) => (state.propertyTypeCount = data),
  setListOfFlagsCount: (state, data) => (state.flagsCount = data),
  setRegionSelect: (state, data) => (state.regions = data),
  setRegionZooplaSelect: (state, data) => (state.zooplaregions = data),
  setPropertyInquiry: (state, data) => (state.propertyinquiry = data),
  setComments: (state, data) => (state.comments = data),
  setComment: (state, data) => (state.comment = data),
  setPropertiesCount: (state, data) => (state.propertiesCount = data),
  setPropertyReport: (state, data) => (state.propertiesReport = data),
  setPostCountReport: (state, data) => (state.postcodCountReport = data),
  setWishlistProperty: (state, data) => (state.wishlists = data),
  setRentProperties: (state, data) => (state.rentProperties = data),
  setImportCSV: (state, data) => (state.importCSV = data),
  setOffMarketProperty: (state, data) => (state.offMarketProperty = data),
  setFixImage: (state, data) => (state.fixImage = data),
  setPublishedProperty: (state, data) => (state.publishedProperty = data),
  setUploadImages: (state, data) => (state.images = data),
  addProperty: (state, data) => state.offMarketProperties.push(data), // Paul added
};

export default { state, getters, actions, mutations };
