import Vue from "vue";
import Vuex from "vuex";
import menus from "./modules/menus";
import product from "./modules/product";
import user from "./modules/user";
import property from "./modules/property";
import processml from "./modules/processml";
import table from "./modules/table";
import whitelabel from "./modules/whitelabel";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    menus,
    product,
    user,
    property,
    processml,
    table,
    whitelabel
  },
});
